import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import {
  CancelRounded as CancelIcon,
  AddRounded as AddIcon
} from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSpot } from 'framework';
import { LeaveRequest } from 'domain-types';
import {
  Row,
  Spacer,
  Table,
  Line,
  TitleContainer,
  Capitalized,
  ConfirmDialog,
  RequestLeaveDialog,
  LeaveRequestResult,
  LimitedText,
  getLeaveIcon,
  getLeaveTypeIcon
} from 'components';

interface LeaveRequestDialogDesc {
  callback: (leaveRequest: LeaveRequestResult) => Promise<void>;
}

interface ConfirmDialogDesc {
  callback: () => Promise<void>;
  title: string;
  text: string;
}

export function LeaveRequests({
  id,
  organization
}: {
  id: string;
  organization: string;
}) {
  const { t } = useTranslation();
  const { data, command, query } = useSpot();

  const user = data?.users?.[id];
  const org = data?.organizations?.[organization];

  const [
    requestLeaveDialog,
    setRequestLeaveDialog
  ] = useState<LeaveRequestDialogDesc | null>(null);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogDesc | null>(
    null
  );

  const refreshLeaveRequests = useCallback(async () => {
    await query(`org/${organization}/user/${id}/leave`, {}, [
      'users',
      id,
      'leave'
    ]);
  }, [organization, id, query]);

  const shouldLookupUser = !data?.users?.[id];

  useEffect(() => {
    (async () => {
      if (shouldLookupUser) {
        await query(`org/${organization}/user/${id}`, {}, ['users', id]);
      }
      await refreshLeaveRequests();
    })();
  }, [refreshLeaveRequests, query, organization, id, shouldLookupUser]);

  const canCancelLeave = (leave: LeaveRequest) => {
    const tooOld = leave.startDate < new Date().toISOString();
    const wrongState = leave.state !== 'requested' && leave.state !== 'filed';
    return !tooOld && !wrongState;
  };

  const onCancelLeaveRequest = (requestId: string) => {
    setConfirmDialog({
      callback: async () => {
        await command(
          `org/${organization}/user/${id}/leave/${requestId}/cancel`
        );
        await query(`org/${organization}/user/${id}/leave`, {}, [
          'users',
          id,
          'leave'
        ]);

        setConfirmDialog(null);
      },
      title: t('confirmCancelLeaveRequestTitle'),
      text: t('confirmCancelLeaveRequestSubtitle')
    });
  };

  const onRequestLeave = () => {
    setRequestLeaveDialog({
      callback: async (leaveRequest: LeaveRequestResult) => {
        await command(`org/${organization}/user/${id}/leave`, {
          ...leaveRequest
        });
        await query(`org/${organization}/user/${id}/leave`, {}, [
          'users',
          id,
          'leave'
        ]);

        setRequestLeaveDialog(null);
      }
    });
  };

  const leaveTypes = Object.keys(org?.leaveTypes || {}).filter(
    type => !org?.disabledLeaveTypes.includes(type)
  );
  
  return (
    <>
      <TitleContainer>
        <Capitalized variant="h5">{t('leaveRequests')}</Capitalized>
      </TitleContainer>
      <Line />
      <Row mb={1}>
        <Spacer />
        <Button
          color="primary"
          startIcon={<AddIcon />}
          aria-label="add"
          onClick={onRequestLeave}
        >
          {t('requestLeave')}
        </Button>
      </Row>
      <Table
        maxHeight="580px"
        headers={[
          t('status'),
          t('type'),
          t('start'),
          t('end'),
          t('reason'),
          ''
        ]}
        rows={user?.leave
          ? Object.values(user?.leave)
            .sort((reqA, reqB) => {
              if (reqB.state === 'canceled') {
                return -1;
              }
              if (reqA.state === 'canceled') {
                return 1;
              }

              return reqB.startDate.localeCompare(reqA.startDate);
            })
            .map(leave => ({
              id: leave.id,
              disabled: leave.state === 'canceled',
              cells: [
                <Box key="state" title={t(leave.state)}>
                  {getLeaveIcon(leave.state)}
                </Box>,
                <Box key="type" title={t(leave.type)}>
                  {getLeaveTypeIcon(leave.type)}
                </Box>,
                moment(leave.startDate, 'YYYY-MM-DD').format('LL'),
                moment(leave.endDate, 'YYYY-MM-DD').format('LL'),
                <LimitedText
                  key="reason"
                  title={leave.reason}
                  variant="body2"
                >
                  {leave.reason}
                </LimitedText>,
                <>
                  {canCancelLeave(leave) && (
                    <IconButton
                      onClick={() => onCancelLeaveRequest(leave.id)}
                    >
                      <CancelIcon color="secondary" />
                    </IconButton>
                  )}
                </>
              ]
            }))
          : []} />
      <ConfirmDialog
        title={confirmDialog?.title ?? ''}
        text={confirmDialog?.text ?? ''}
        dialogOpen={!!confirmDialog}
        onClose={() => setConfirmDialog(null)}
        onSubmit={confirmDialog?.callback} />
      <RequestLeaveDialog
        title={t('requestLeaveTitle')}
        hoursPerDay={user?.hoursPerDay ?? 0}
        leaveTypes={leaveTypes}
        publicHolidays={org?.publicHolidays ?? []}
        text={t('requestLeaveSubtitle')}
        dialogOpen={!!requestLeaveDialog}
        onClose={() => setRequestLeaveDialog(null)}
        initialValue={{ type: leaveTypes[0] }}
        onSubmit={requestLeaveDialog?.callback} />
    </>
  );
}
